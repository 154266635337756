<template>
  <div class="v-stack h-stretch gap-3" v-if="$store.state.accessLevel > 9">
    <select v-model="selectedUser" @input="onInput($event)">
      <option :value="null">All</option>
      <option :value="'crew'">All without Admin</option>
      <option v-for="user in users" :key="user._id" :value="user._id">
        {{ user.fullName }}
      </option>
    </select>
    <Hours
      :selectedUser="selectedUser"
      :workdays="workdays"
      :oneTimePay="oneTimePay"
      v-on:refresh="refresh()"
    ></Hours>
  </div>
</template>

<script>
import Hours from "@/components/Hours.vue";

export default {
  data() {
    return {
      selectedUser: "crew",
    };
  },
  components: {
    Hours,
  },
  props: {
    workdays: {
      type: Array,
      default: () => [],
    },
    oneTimePay: {
      type: Array,
      default: () => [],
    },
    users: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    onInput(event) {
      if (this.$route.query.user != event.target.value) {
        this.$router.push("/lifefinance/crewhours?user=" + event.target.value);
      }
    },
    refresh() {
      this.$emit("refresh");
    },
  },
  mounted() {
    if ("user" in this.$route.query) {
      if (this.$route.query.user) {
        this.selectedUser = this.$route.query.user;
      } else {
        this.selectedUser = null;
      }
    }
  },
};
</script>