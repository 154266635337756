<template>
  <div class="v-stack h-stretch gap-3">
    <div class="h-stack h-space-between">
      <div class="h-stack h-start gap-3">
        <label>Show:</label>
        <select v-model="filter" style="width: 130px">
          <option value="">All</option>
          <option value="workdays">Workdays</option>
          <option value="bonus">Bonus</option>
          <option value="advance">Advance</option>
          <option value="fine">Fine</option>
        </select>
      </div>
      <div class="h-stack h-start gap-3">
        <label>Details:</label>
        <input v-model="showWorkHours" type="checkbox" />
        <label>Signed:</label>
        <input v-model="signed" type="checkbox" />
        <label>Unsigned:</label>
        <input v-model="unsigned" type="checkbox" />
        <label>Canceled:</label>
        <input v-model="canceled" type="checkbox" />
      </div>
    </div>
    <div class="card light">
      <div class="content">
        <div class="v-stack gap-3">
          <a>Period overview</a>
          <div class="pane-horizontal gap-3">
            <div class="text-right">Unsigned:</div>
            <div class="text-left">{{ unsignedCountThisMonth }}</div>
          </div>
          <div class="pane-horizontal gap-3">
            <div class="text-right">Money:</div>
            <div class="text-left">
              {{ format.currency(revenueThisMonth) }} Kč
            </div>
          </div>
          <div class="pane-horizontal gap-3">
            <div class="text-right">Hours:</div>
            <div class="text-left">{{ Math.round(hoursThisMonth) }}</div>
          </div>
        </div>
      </div>
    </div>
    <TableWorkdays :pageable="true" :workdays="workdaysFiltered" :oneTimePay="oneTimePayFiltered"
      :showWorkHours="showWorkHours" v-on:refresh="refresh()" :showName="true"
      :allowSign="$store.state.accessLevel > 9 ? true : false">
    </TableWorkdays>
  </div>
</template>

<script>
import TableWorkdays from "@/components/TableWorkdays.vue";
import utils from "@/utils.js";

export default {
  data() {
    return {
      filter: "",
      signed: true,
      unsigned: true,
      canceled: true,
      format: utils.format,
      showWorkHours: false,
    };
  },
  components: {
    TableWorkdays,
  },
  props: {
    selectedUser: {
      type: String,
      default: null,
    },
    workdays: {
      type: Array,
      default: () => [],
    },
    oneTimePay: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    users() {
      let users = this.workdays
        .map((item) => item.user)
        .concat(this.oneTimePay.map((item) => item.user));
      if (users.length > 0) {
        return users.reduce((filter, current) => {
          var dk = filter.find((item) => item._id === current._id);
          if (!dk) {
            return filter.concat([current]);
          } else {
            return filter;
          }
        }, []);
      }
      return users;
    },
    workdaysFiltered() {
      // catch workdays
      let workdays = this.filterSelection(this.workdays);

      // apply filters

      if (!this.signed) {
        workdays = workdays.filter((item) => item.state != "signed");
      }
      if (!this.unsigned) {
        workdays = workdays.filter((item) => item.state != "created");
      }
      if (!this.canceled) {
        workdays = workdays.filter((item) => item.state != "canceled");
      }

      if (this.filter == "" || this.filter == "workdays") {
        return workdays;
      }

      return [];
    },
    oneTimePayFiltered() {
      // catch oneTimePay
      let oneTimePay = this.filterSelection(this.oneTimePay);

      // apply filters
      if (!this.signed) {
        oneTimePay = oneTimePay.filter((item) => item.state != "signed");
      }
      if (!this.unsigned) {
        oneTimePay = oneTimePay.filter((item) => item.state != "created");
      }
      if (!this.canceled) {
        oneTimePay = oneTimePay.filter((item) => item.state != "canceled");
      }

      if (this.filter == "workdays") {
        return [];
      } else {
        if (this.filter == "bonus") {
          return oneTimePay.filter((item) => item.amount > 0);
        }

        if (this.filter == "advance") {
          return oneTimePay.filter(
            (item) => item.amount < 0 && item.fine != true
          );
        }

        if (this.filter == "fine") {
          return oneTimePay.filter(
            (item) => item.amount < 0 && item.fine == true
          );
        }
      }

      return oneTimePay;
    },
    hoursThisMonth() {
      let workdays = this.workdays;
      workdays = workdays.filter((item) => item.state == "signed");

      workdays = this.filterSelection(workdays);

      return utils.finance.workdayHours(workdays);
    },
    unsignedCountThisMonth() {
      let workdays = this.workdays;

      workdays = workdays.filter((item) => item.state == "created");

      let oneTimePay = this.oneTimePay;

      oneTimePay = oneTimePay.filter((item) => item.state == "created");

      workdays = this.filterSelection(workdays);
      oneTimePay = this.filterSelection(oneTimePay);

      return workdays.concat(oneTimePay).length;
    },
    revenueThisMonth() {
      // catch workdays
      let workdays = this.workdays;

      // apply filters
      workdays = workdays.filter((item) => item.state == "signed");

      // catch oneTimePay

      let oneTimePay = this.oneTimePay;

      // apply filters
      oneTimePay = oneTimePay.filter((item) => item.state == "signed");

      workdays = this.filterSelection(workdays);
      oneTimePay = this.filterSelection(oneTimePay);

      return utils.finance.revenue(workdays, oneTimePay);
    },
  },
  methods: {
    refresh() {
      this.$emit("refresh");
    },
    filterSelection(array) {
      let result = array;
      if (this.selectedUser) {
        if (this.selectedUser == "crew") {
          result = result.filter((item) => {
            return item.user.accessLevel < 10;
          });
        } else {
          result = result.filter((item) => item.user._id == this.selectedUser);
        }
      }

      return result;
    },
  },
};
</script>

<style scoped>
label,
input {
  align-self: center;
}
</style>
